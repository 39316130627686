import React from 'react'
import { Link } from 'gatsby';
import { connect } from 'react-redux';

const Logo = ({ activeLanguage }) => (
  <div className="logo verticals">
    <Link to='/'>
      <img src="/icons/logo.svg" alt="logo" />
    </Link>

    <div className="change-language">
      <div className="active-language">
        { activeLanguage }
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ activeLanguage }) => ({ activeLanguage });

export default connect(mapStateToProps)(Logo);