import React, { useState } from 'react';
import { Link } from 'gatsby';

const MenuItemDropdown = ({ data, curPage, resetBodyOverflow }) => {
  const [ listShown, setListShown ] = useState(false);
  const toggleListShown = () => setListShown(!listShown);

  return (
    <li
      className={
        `menu-item-dropdown ${
          data.to.some(el => el.to === curPage) ? 'active' : ''
        } ${listShown ? 'list-shown' : ''}`
      }
      onClick={toggleListShown}
    >
      <div className="dropdown-title">
        <span>
          { data.text }

          <div className="arrow">
            <div></div>
          </div>
        </span>
      </div>
      <ul>
        {data.to.map(({ to, text }) => (
          <li className={curPage === to ? 'active' : ''} key={to} onClick={resetBodyOverflow}>
            <Link to={to}>{ text }</Link>
          </li>
        ))}
      </ul>
    </li>
  )
}

export default MenuItemDropdown;
