import React from 'react';

const Burger = ({ onClick }) => (
  <button onClick={onClick}>
    <div className="top"></div>
    <div className="mid"></div>
    <div className="bot"></div>
  </button>
);

export default Burger;