import React, { Component } from "react";
import Logo from './logo';
import CallButton from './callButton';
import MenuItem from './menuItem';
import MenuItemDropdown from './menuItemDropdown';
import Burger from './burger';
import { Link } from 'gatsby';

const sections = [
  { text: 'Главная', to: '/' },
  { text: 'О комплексе', to: '/about' },
  {
    text: 'Планировки',
    to: [
      { text: 'Таунхаусы', to: '/townhouses' },
      { text: 'Квартиры', to: '/flats' },
    ]
  },
  { text: 'Галерея', to: '/gallery' },
  { text: 'Контакты', to: '/contactus' },
  { text: 'Ход строительства', to: '/progress' }
];

class Header extends Component {
  state = { curPage: '/' };

  onBurgerClick = e => {
    this.props.toggleBg();
    e.currentTarget.classList.toggle('active');
  }

  componentDidMount() {
    this.setState({ curPage: window.location.pathname });
  }

  render() {
    const { active, style } = this.props;

    return (
      <header className="hero-header" style={style}>
        <Logo />

        <nav className={`menu ${active ? 'active' : ''}`}>
          <ul ref={this.sectionsList}>
            {
              sections.map(s => {
                return (Array.isArray(s.to))
                  ? <MenuItemDropdown data={s} key={s.text} curPage={this.state.curPage} resetBodyOverflow={this.resetBodyOverflow} />
                  : <MenuItem text={s.text} to={s.to} key={s.text} curPage={this.state.curPage} onClick={this.resetBodyOverflow} />;
              })
            }

            <div className="extras">
              <div className="row">
                <div className="item">
                  <div className="icon-wrapper">
                    <img src="/icons/phone-icon.svg" alt="phone number: 073 562 55 82" style={{ maxWidth: 25 }} />
                  </div>
                  <a href="https://t.me/dresden777" target="_blank" rel="noopener noreferrer">
                    <span>073 753 03 03</span>
                  </a>
                </div>

                <div className="item">
                  <div className="icon-wrapper">
                    <img src="/icons/location-icon.svg" alt=" " />
                  </div>
                  <a href="https://goo.gl/maps/KxZ5o4wBdwn8uxYC7" target="_blank" rel="noopener noreferrer">
                    <span>Проспект дружбы народов, г. Днепр</span>
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="item">
                  <div className="icon-wrapper">
                    <img src="/icons/instagram-icon.svg" alt="instagram link" />
                  </div>
                  <a href="https://www.instagram.com/dresden.dp.ua/" target="_blank" rel="noopener noreferrer">
                    <span>INSTAGRAM</span>
                  </a>
                </div>

                <div className="item">
                  <div className="icon-wrapper">
                    <img src="/icons/facebook-icon.svg" alt="facebook link" />
                  </div>
                  <a href="https://www.facebook.com/dresdendpua-461438367972547/" target="_blank" rel="noopener noreferrer">
                    <span>FACEBOOK</span>
                  </a>
                </div>
              </div>

              <Link to="/contactus">
                <button>СВЯЗАТЬСЯ</button>
              </Link>
            </div>
          </ul>

          <Burger onClick={this.onBurgerClick} />
        </nav>

        <CallButton />
      </header>
    )
  }
}

export default Header
