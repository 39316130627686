import '../styles/index.scss';
import React, { Component } from 'react';
import SEO from '../components/seo';
import Header from '../components/header';

class ContactUsPage extends Component {
  state = {
    menuBgVisible: false,
    name: '',
    phone: '',
    message: ''
  }

  handleNameInput = e => {
    const { value } = e.target;
    if (value.match(/[А-Яа-я\s]/) || value === '') this.setState({ name: value });
  }

  handlePhoneInput = e => {
    const { value } = e.target;
    if (value.length <= 10 || value === '') this.setState({ phone: value });
  }

  componentDidMount() {
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    if (bodyOverflow === 'hidden') document.documentElement.style.overflow = 'visible';
  }

  toggleBg = () => this.setState(prev => ({ menuBgVisible: !prev.menuBgVisible }));

  render() {
    const { menuBgVisible, name, phone } = this.state;

    return (
      <div className="contact-us-page section-bg-dark page">
        <SEO title="Контакты" />

        <div className={`menu-bg ${menuBgVisible ? "active" : ""}`} />

        <span className="bg-title">CONTACT US</span>

        <Header
          toggleBg={this.toggleBg}
          active={menuBgVisible}
          curPage="/contactus"
        />

        <div className="content">
          <div className="line horizontal bottom" />
          <div className="headers">
            <h1>СВЯЖИТЕСЬ С НАМИ</h1>

            <p>
              Вы можете связаться с нами по номеру телефона, Telegram, Facebook или Instagram. Либо же просто введите ваши данные в форму обратной связи и наш менеджер с Вами свяжется.
            </p>

          </div>

          <div className="forms">
            <div className="feedback-form">
              <form method="POST" action="/form.php">
                <div className="row">
                  <input type="text" name="name" placeholder="ВАШЕ ИМЯ" value={name} onChange={this.handleNameInput} />
                  <input type="number" name="phone" placeholder="ВАШ НОМЕР ТЕЛЕФОНА" value={phone} onChange={this.handlePhoneInput} />
                </div>
                <div className="row">
                  <input type="text" name="message" placeholder="ВАШЕ СООБЩЕНИЕ" />
                </div>
                <div className="row">
                  <input type="submit" value="ОТПРАВИТЬ" />
                </div>
              </form>
            </div>

            <div className="socials">
              <div className="row">
                <div className="social-item">
                  <a href="https://t.me/dresden777" target="_blank" rel="noopener noreferrer">
                    <div className="icon-wrapper">
                      <img src="/icons/telegram.svg" alt="phone number: 073 753 03 03" style={{ maxWidth: 25 }} />
                    </div>

                    <span>073 753 03 03</span>
                  </a>
                </div>


                <div className="social-item">
                  <a href="https://www.instagram.com/dresden.dp.ua/" target="_blank" rel="noopener noreferrer">
                    <div className="icon-wrapper">
                      <img src="/icons/instagram-icon.svg" alt="instagram link" />
                    </div>

                    <span>INSTAGRAM</span>
                  </a>
                </div>
              </div>


              <div className="row">
                <div className="social-item">
                  <div>
                    <div className="icon-wrapper">
                      <img src="/icons/location-icon.svg" alt=" " />
                    </div>
                    <span>Проспект дружбы народов, г. Каменское (р-н Эпицентра)</span>
                  </div>
                </div>


                <div className="social-item">
                  <a href="https://www.facebook.com/dresdendpua-461438367972547/" target="_blank" rel="noopener noreferrer">
                    <div className="icon-wrapper">
                      <img src="/icons/facebook-icon.svg" alt="facebook link" />
                    </div>

                    <span>FACEBOOK</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <span>Все права сохранены. 2019 Dresden. Creative House Group</span>
      </div>
    )
  }
}

export default ContactUsPage;
