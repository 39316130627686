import React from 'react';
import { Link } from 'gatsby';

const MenuItem = ({ text, to, curPage }) => {
  let active = false;

  if (to === curPage) {
    active = true;
  } else {
    let rx = to
      .split('')
      .map(c => {
        if (c === '/') return '';
        return c;
      })
      .join('');

    if (rx && curPage.match(new RegExp(rx)))
      active = true;
  }

  return (
    <Link to={to}>
      <li className={active ? 'active' : ''}>
        { text }
      </li>
    </Link>
  )
}

export default MenuItem;
