import React from 'react';

const CallButton = () => (
  <div className="call-button">
    <div
      className="phone verticals"
      onClick={_e => {
        window.scrollTo({
          left: 0,
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        })
      }}
    >
      <img src="/icons/phone.svg" alt="phone icon" />
    </div>
  </div>
)

export default CallButton;